import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import {
    EncodedIdByHashIdGQL,
    ItemByEncodedIdDocument,
    ItemByEncodedIdQuery,
    ItemByIdDocument,
    ItemByIdQuery, SearchedItemFragment
} from '../../../../graphql/generated';
import { toSignal } from '@angular/core/rxjs-interop';

// Configuration
export interface CurrentItemServiceConfig {
    pollInterval?: number;
}

export const CURRENT_ITEM_SERVICE_CONFIG = new InjectionToken<CurrentItemServiceConfig>(
    'CURRENT_ITEM_SERVICE_CONFIG'
);

// Custom error types
export class ItemNotFoundError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ItemNotFoundError';
    }
}

export class NetworkError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'NetworkError';
    }
}

// State interface
interface CurrentItemState {
    item: SearchedItemFragment | null;
    loading: boolean;
    error: Error | null;
}

@Injectable({
    providedIn: 'root'
})
export class CurrentItemService {
    private state$ = new BehaviorSubject<CurrentItemState>({
        item: null,
        loading: false,
        error: null
    });

    private readonly defaultConfig: CurrentItemServiceConfig = {
        pollInterval: 0 // Default to no polling
    };

    constructor(
        private readonly apollo: Apollo,
        private readonly encodedIdByHashIdGQL: EncodedIdByHashIdGQL,
        @Optional() @Inject(CURRENT_ITEM_SERVICE_CONFIG) private config: CurrentItemServiceConfig
    ) {
        this.config = { ...this.defaultConfig, ...config };
    }

    public get item$(): Observable<SearchedItemFragment | null> {
        return this.state$.asObservable().pipe(
            map(state => state.item),
            distinctUntilChanged((prev, curr) => this.compareItems(prev, curr)),
            shareReplay(1)
        );
    }

    public readonly item = toSignal(this.item$);

    public get loading$(): Observable<boolean> {
        return this.state$.asObservable().pipe(
            map(state => state.loading),
            shareReplay(1)
        );
    }

    public readonly loading = toSignal(this.loading$);

    public get error$(): Observable<Error | null> {
        return this.state$.asObservable().pipe(
            map(state => state.error),
            shareReplay(1)
        );
    }

    public readonly error = toSignal(this.error$);

    public setCurrentItem(item: SearchedItemFragment | null): void {
        this.updateState({ item, loading: false, error: null });
    }

    public setItemById(id: string): Observable<SearchedItemFragment> {
        this.updateState({ loading: true, error: null });
        return this.apollo.watchQuery<ItemByIdQuery>({
            query: ItemByIdDocument,
            variables: { id },
            pollInterval: this.config.pollInterval
        }).valueChanges.pipe(
            map(result => result.data.itemById as SearchedItemFragment),
            tap(item => {
                if (item) {
                    this.updateState({ item, loading: false, error: null });
                } else {
                    throw new ItemNotFoundError(`No item found for id: ${id}`);
                }
            }),
            catchError(this.handleError)
        );
    }

    public setItemByEncodedId(encodedId: string): Observable<SearchedItemFragment> {
        this.updateState({ loading: true, error: null });

        return this.apollo.watchQuery<ItemByEncodedIdQuery>({
            query: ItemByEncodedIdDocument,
            variables: { encodedId },
            pollInterval: this.config.pollInterval
        }).valueChanges.pipe(
            map(result => result.data.itemByEncodedId),
            tap(item => {
                if (item) {
                    this.updateState({ item, loading: false, error: null });
                } else {
                    throw new ItemNotFoundError(`No item found for encodedId: ${encodedId}`);
                }
            }),
            map(item => item as SearchedItemFragment),
            catchError(this.handleError)
        );
    }

    public setEncodedIdByHashId(hashId: string): Observable<SearchedItemFragment> {
        return this.encodedIdByHashIdGQL.watch({ hashId }).valueChanges.pipe(
            map(result => result.data.encodedIdWrapper?.encodedId),
            tap(encodedId => {
                if (encodedId) {
                    this.setItemByEncodedId(encodedId);
                } else {
                    throw new ItemNotFoundError(`No item found for hashId: ${hashId}`);
                }
            }),
            catchError(this.handleError)
        );
    }

    public setItemBySlug(slug: string): Observable<SearchedItemFragment> {
        const encodedId = slug.split('-').pop();
        if (!encodedId) {
            return throwError(() => new Error('Invalid slug format'));
        }

        return this.setItemByEncodedId(encodedId).pipe(
            catchError(error => {
                if (error instanceof ItemNotFoundError) {
                    return this.setEncodedIdByHashId(slug);
                }
                return throwError(() => error);
            })
        );
    }

    public clearCurrentItem(): void {
        this.updateState({ item: null, loading: false, error: null });
    }

    private updateState(partialState: Partial<CurrentItemState>): void {
        this.state$.next({ ...this.state$.value, ...partialState });
    }

    private handleError = (error: any) => {
        this.updateState({ loading: false, error });
        return throwError(() => new NetworkError('Failed to fetch item'));
    }

    private compareItems(prev: SearchedItemFragment | null, curr: SearchedItemFragment | null): boolean {
        if (prev === curr) return true;
        if (!prev || !curr) return false;
        return prev.id === curr.id; // Compare based on unique identifier
    }
}
