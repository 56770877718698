@if (loading()) {
    <p-progressBar color="red" mode="indeterminate" [style]="{ height: '3px' }"></p-progressBar>
}
<p-table
    #table
    [scrollable]="true"
    [virtualScroll]="true"
    [selectionMode]="'single'"
    [virtualScrollItemSize]="50"
    [value]="combinedResults()"
    [rowTrackBy]="trackHitByItemId"
    [loading]="loading()"
    [(selection)]="selectedItem"
    (sortFunction)="customSort($event)"
    [customSort]="true"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowUnselect($event)"
    scrollHeight="calc(100vh - 25rem)"
>

    <ng-template pTemplate="colgroup">
        <colgroup>
            <col style="width: 8.33%">
            <col style="width: 16.67%">
            <col style="width: 16.67%">
            <col style="width: 16.67%">
            <col style="width: 16.67%">
            <col style="width: 25%">x
        </colgroup>
    </ng-template>

    <ng-template pTemplate="header">
        <tr>
            <th class="text-xs text-left py-2" colspan="1"></th>
            <th
                pSortableColumn="item.category.name"
                class="text-xs font-semibold py-2 pl-4"
                colspan="2"
            >
                <div class="flex justify-between items-center w-full">
                    <span>Wat/Bedrijf</span>
                    <p-sortIcon field="item.category.name"></p-sortIcon>
                </div>
            </th>
            <th
                pSortableColumn="item.location.properties.formattedAddress"
                class="text-xs font-semibold py-2 pl-4"
                colspan="2"
            >
                <div class="flex justify-between items-center w-full">
                    <span>Waar</span>
                    <p-sortIcon field="item.location.properties.formattedAddress"></p-sortIcon>
                </div>
            </th>
            <th
                class="text-xs font-semibold text-left py-2 pl-4"
                colspan="1"
            >
                Wanneer
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="loading">
        <tr>
            <td class="text-center" colspan="6" >
                <ng-container i18n="@@component.search-supply-result-table.loading">
                    Laden...
                </ng-container>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="6">
                <div class="px-6 py-4 opacity-55 text-sm text-nowrap">
                    <p>Geen items die voldoen aan de criteria. Zoom de kaart uit om meer items te zien.</p>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-result>
        <tr
            app-result-table-item
            [pSelectableRow]="result"
            [id]="result.item.id"
            [result]="result"
            [ngClass]="{'bg-slate-100': result.isSecondary}"
        >
        </tr>

    </ng-template>
</p-table>
